import React, {useEffect, useRef} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {Container, makeStyles} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import * as parseAutosuggest from "autosuggest-highlight/parse";
import {getAccount, updateAccountUsers, cleanAccount} from "../../../actions/accountActions";
import {getUsers} from "../../../actions/userActions";
import UserTable from "../account-create/UserTable";
import AddUserIcon from '@material-ui/icons/AddCircle';
import * as matchAutosuggest from 'autosuggest-highlight/match';
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
    titleContainer: {
        margin: theme.spacing(1, 0, 2),
    },
    root: {
        padding: theme.spacing(3),
    },
    accountDetailsContainer: {
        margin: theme.spacing(0, 0, 2)
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        padding: theme.spacing(2, 0, 0),
    },
    formBox: {
        margin: theme.spacing(0, 0, 4),
        padding: theme.spacing(2, 4, 4, 4),
        borderRadius: '4px',
        boxShadow: '0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12);'
    },
    fullWidth: {
        width: '100%'
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textFieldLeft: {
        marginRight: theme.spacing(2),
        width: `calc((100% - 16px)/2)`
    },
    textField: {
        width: `calc((100% - 16px)/2)`
    },
    spaceBetween: {
        marginRight: theme.spacing(2),
    },
    closeButton: {
        borderRadius: `4px !important`,
        marginRight: `16px !important`
    },
    progress: {
        margin: theme.spacing(0.5),
    },
    loadingContainer: {
        textAlign: 'center',
    },
    columnTitle: {
        width: `calc(100% - 8px)`,
        margin: '16px 8px 0',
    },
    autocomplete: {
        margin: theme.spacing(2, 2, 1, 0),
        width: `calc((100% - 16px)/2)`,
        display: 'inline-flex'
    },
    userFormContainer: {
        marginBottom: theme.spacing(4)
    },
    alignRight: {
        textAlign: 'right'
    }
}));

const AccountUserPage = ({getAccount, account, getUsers, users, match, updateAccountUsers, cleanAccount}) => {

    const classes = useStyles();
    const accountId = match.params.id;
    const [usersList, setUsersList] = React.useState([]);
    const [showAddUserForm, setShowAddUserForm] = React.useState(false);
    const [existingUserForm, setExistingUserForm] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [userSelected, setUserSelected] = React.useState(false);
    const [editingUser, setEditingUser] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [showCreateNewUserForm, setShowCreateNewUserForm] = React.useState(true);
    const formikRef = useRef();

    const userSettingsDefaultValues = () => {
        return {
            email_notification: false,
            message_notification: false,
            phone_notification: false,
            app_notification: false,
            alarm_notification: false
        };
    };

    const [userSettings, setUserSettings] = React.useState(userSettingsDefaultValues());

    useEffect(() => {
        setLoading(true);
        getAccount(accountId);
        if (!(users.data && users.data.length)) {
            getUsers(1);
        }
        return () => {
            cleanAccount();
        }
    }, []);

    useEffect(() => {
        if (account.account && account.account.account_id) {
            setUsersList(mapContactListToUsersList());
            setShowAddUserForm(!account.account.contact_list.length);
            setLoading(false);
        }
    }, [account]);

    const validationRules = Yup.object().shape({
        user_id: Yup.string(),
        email: Yup.string()
            .required('Required'),
        contact_type: Yup.number()
            .required('Required'),
        first_name: Yup.string()
            .required('Required'),
        last_name: Yup.string()
            .required('Required'),
        password: Yup.string()
            .when('user_id', {
                is: (val) => val,
                then: Yup.string()
                    .min(8, 'Password must be 8 characters long')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_#])[A-Za-z\d$@$!%*?&_#]/, 'Must contain a number, one uppercase character, one lowercase character and a special character such as $, @, !, %, *, ?, &, _, #'),
                otherwise: Yup.string()
                    .required('Required')
                    .min(8, 'Password must be 8 characters long')
                    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$@$!%*?&_#])[A-Za-z\d$@$!%*?&_#]/, 'Must contain a number, one uppercase character, one lowercase character and a special character such as $, @, !, %, *, ?, &, _, #')
            }),
        repeat_password: Yup.string()
            .when('password', {
                is: (val) => val,
                then: Yup.string()
                    .required('Required')
                    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
                otherwise: Yup.string()
            }),
        primary_phone: Yup.string()
            .when('user_id', {
                is: (val) => val,
                then: Yup.string()
                    .matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'}),
                otherwise: Yup.string()
                    .matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
                    .required('Required'),
            }),
        secondary_phone: Yup.string().matches(/[\d() -]+$/, {message: 'Format valid: (999) 999-9999'})
    });

    const mapUsersListToObject = () => {
        const users = usersList.map(user => {
            if (user.user_id) {
                return {
                    user_id: user.user_id,
                    contact_type: user.contact_type,
                    user_setting: user.user_setting
                };
            } else {
                return {
                    email: user.email,
                    contact_type: user.contact_type,
                    first_name: user.first_name,
                    last_name: user.last_name,
                    primary_phone: user.primary_phone,
                    secondary_phone: user.secondary_phone || '',
                    password: user.password,
                    user_setting: user.user_setting
                };
            }
        });
        return users;
    };

    const LoadingSpinner = () => {
        return (
            <div className={classes.loadingContainer}>
                <CircularProgress className={classes.progress}/>
            </div>
        );
    };

    const mapContactListToUsersList = () => {
        const users = account.account.contact_list.map(contact => {
            return {
                user_id: contact.user_id,
                email: contact.user.email,
                contact_type: contact.contact_type,
                first_name: contact.user.first_name,
                last_name: contact.user.last_name,
                primary_phone: contact.primary_phone_call,
                secondary_phone: contact.secondary_phone || '',
                user_setting: {
                    email_notification: contact.email_notification,
                    message_notification: contact.message_notification,
                    phone_notification: contact.phone_notification,
                    app_notification: contact.app_notification,
                    alarm_notification: contact.alarm_notification,
                }
            };
        });
        return users;
    };

    const mapObjectToValues = () => {
        return {
            user_id: editingUser.user_id || '',
            email: editingUser.email || '',
            contact_type: editingUser.contact_type || '',
            first_name: editingUser.first_name || '',
            last_name: editingUser.last_name || '',
            primary_phone: editingUser.primary_phone || '',
            secondary_phone: editingUser.secondary_phone || '',
            password: '',
            repeat_password: '',
            user_setting: editingUser.user_settings || {}
        };
    };

    const handleCheckboxChange = (name) => (event) => {
        setUserSettings({...userSettings, [name]: event.target.checked});
    };

    const userListHaveAPrimeryUser = () => {
        const primaryUser = usersList.filter(user => {
            return user.contact_type === 1;
        });
        return !!primaryUser.length;
    };

    const closeForm = () => {
        setShowAddUserForm(false);
        setShowCreateNewUserForm(false);
        setUserSettings(userSettingsDefaultValues());
        setExistingUserForm(false);
        setEditingUser({});
    };

    const submitForm = () => {
        const data = {
            account_id: accountId,
            users: mapUsersListToObject()
        };
        updateAccountUsers(data);
    };

    const addNewUser = (values) => {
        values.user_setting = userSettings;
        setUsersList(oldArray => [...oldArray, values]);
    };

    const editExistingUser = (values) => {
        values.user_setting = userSettings;
        const newUsersList = usersList.filter(item => item.email !== values.email);
        newUsersList.push(values);
        setUsersList(newUsersList);
    };

    const deleteUser = (rowData) => {
        setUsersList(usersList.filter(item => item.tableData.id !== rowData.tableData.id));
    };

    const editUser = (rowData) => {
        const userObject = users.data.find(item => {
            return (item.email === rowData.email) ? item.user_id : '';
        });
        let user = Object.assign({}, rowData);
        delete user.tableData;
        setShowAddUserForm(true);
        if(userObject && userObject.user_id) {
            user.user_id = userObject.user_id;
            setExistingUserForm(true);
        }else{
            setExistingUserForm(false);
        }
        setEditingUser(user);
    };

    const mapValuesToField = (value) => {
        if (value) {
            formikRef.current.setFieldValue('user_id', value.user_id || '');
            formikRef.current.setFieldValue('email', value.email || '');
            formikRef.current.setFieldValue('first_name', value.first_name || '');
            formikRef.current.setFieldValue('last_name', value.last_name || '');
            formikRef.current.setFieldValue('primary_phone', value.primary_phone || '');
            formikRef.current.setFieldValue('secondary_phone', value.secondary_phone || '');
            setExistingUserForm(true);
        }
        if (value === null) {
            formikRef.current.resetForm();
            mapValuesToField({});
            setExistingUserForm(false);
        }
    };

    const onChangeEmail = (event) => {
        formikRef.current.setFieldValue('user_id', '');
        formikRef.current.setFieldValue('email', event.target.value || '');
        formikRef.current.setFieldValue('first_name', '');
        formikRef.current.setFieldValue('last_name', '');
        formikRef.current.setFieldValue('primary_phone', '');
        formikRef.current.setFieldValue('secondary_phone', '');
        setExistingUserForm(false);
    }

    const onBlurUserEmail = (event) => {
        const userAdded = usersList.find(item => item.email === event.target.value);
        setUserSelected(!!(userAdded && userAdded.user_id));
    };

    const onChangeUserEmail = (event, value) => {
        mapValuesToField(value);
    };

    const renderContactTypeField = (errors, values, handleChange, handleBlur, touched) => {
        return (<TextField
            select
            variant="outlined"
            margin="normal"
            error={errors.contact_type && touched.contact_type}
            id="contact_type"
            label="Type"
            name="contact_type"
            value={values.contact_type}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={(errors.contact_type && touched.contact_type) && errors.contact_type}
            className={classes.textField}
        >
            <MenuItem value={1}
                      disabled={userListHaveAPrimeryUser()}>Primary</MenuItem>
            <MenuItem value={2}>Secondary</MenuItem>
        </TextField>);
    }

    const renderUserSettingsFields = () => {
        return (<Grid container>
            <Grid item sm={12}>
                <Typography variant="h6" component="h6"
                            className={classes.columnTitle}>
                    Notifications
                </Typography>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.email_notification}
                        onChange={handleCheckboxChange('email_notification')}
                        color="primary"/>}
                    label="Email"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.phone_notification}
                        onChange={handleCheckboxChange('phone_notification')}
                        color="primary"/>}
                    label="Phone"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.message_notification}
                        onChange={handleCheckboxChange('message_notification')}
                        color="primary"/>}
                    label="Text/SMS"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.alarm_notification}
                        onChange={handleCheckboxChange('alarm_notification')}
                        color="primary"/>}
                    label="Alarm"/>
            </Grid>
            <Grid item sm={2}>
                <FormControlLabel
                    control={<Checkbox
                        checked={userSettings.app_notification}
                        onChange={handleCheckboxChange('app_notification')}
                        color="primary"/>}
                    label="App"/>
            </Grid>
        </Grid>);
    };

    const renderUserForm = () => {
        return (
            <Grid container justify={'center'}>
                {!!usersList.length && <Grid item sm={12} style={{marginBottom: '32px'}}>
                    <Grid container>
                        <Grid item sm={10} style={{marginBottom: '16px'}}>
                            <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                Users List
                            </Typography>
                        </Grid>
                        <Grid item sm={2} style={{marginBottom: '16px'}} className={classes.alignRight}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setShowCreateNewUserForm(true);
                                    setShowAddUserForm(true);
                                }}
                            >
                                Add User <AddUserIcon/>
                            </Button>
                        </Grid>
                    </Grid>
                    <UserTable
                        users={usersList}
                        editingUser={!!editingUser.user_id}
                        onDelete={deleteUser}
                        showRemove={true}
                        onEdit={editUser}
                        showEdit={true}/>
                </Grid>}
                <Grid item sm={12}>
                    <Grid>
                        {showAddUserForm && <Grid>
                            <Grid item sm={12} className={classes.userFormContainer}>
                                <Grid item sm={12}>
                                    <Typography variant="h6" component="h6" className={classes.columnTitle}>
                                        User Information
                                    </Typography>
                                </Grid>
                                <Paper className={classes.root}>
                                    <Formik
                                        validateOnBlur={true}
                                        validateOnChange={true}
                                        enableReinitialize={true}
                                        ref={formikRef}
                                        initialValues={mapObjectToValues()}
                                        validationSchema={validationRules}
                                        onSubmit={(values, {resetForm}) => {
                                            if (editingUser && editingUser.user_id) {
                                                editExistingUser(values);
                                            } else {
                                                addNewUser(values);
                                            }
                                            resetForm();
                                            closeForm();
                                        }}
                                    >
                                        {({handleSubmit, errors, values, handleChange, handleBlur, touched}) => {
                                            if (userSelected) {
                                                errors.email = "This email is already added for this account";
                                                touched.email = true;
                                            } else {
                                                if (errors.email !== "Required") {
                                                    delete errors.email;
                                                }
                                            }
                                            return (
                                                <form onSubmit={handleSubmit} className={classes.form} noValidate>
                                                    <Grid container justify={'center'}>
                                                        <Grid item sm={12}>
                                                            <Grid container>
                                                                <Autocomplete
                                                                    id="field_1_autocomplete"
                                                                    open={open}
                                                                    onOpen={() => {
                                                                        setOpen(true);
                                                                    }}
                                                                    onClose={() => {
                                                                        setOpen(false);
                                                                    }}
                                                                    margin="normal"
                                                                    variant="outlined"
                                                                    className={classes.autocomplete}
                                                                    getOptionLabel={option => option.email}
                                                                    onChange={onChangeUserEmail}
                                                                    onBlur={onBlurUserEmail}
                                                                    options={users.data}
                                                                    filterSelectedOptions={true}
                                                                    loading={users.isFetching}
                                                                    freeSolo={true}
                                                                    autoHighlight={true}
                                                                    defaultValue={() => {
                                                                        if (values.email) {
                                                                            return values;
                                                                        }
                                                                        return;
                                                                    }}
                                                                    disableOpenOnFocus={true}
                                                                    renderOption={(option, {inputValue}) => {
                                                                        const matches = matchAutosuggest(option.email, inputValue);
                                                                        const parts = parseAutosuggest(option.email, matches);

                                                                        return (
                                                                            <div>
                                                                                {parts.map((part, index) => (
                                                                                    <span key={index}
                                                                                          style={{fontWeight: part.highlight ? 700 : 400}}>
                                                                                            {part.text}
                                                                                    </span>
                                                                                ))}
                                                                            </div>
                                                                        );
                                                                    }}
                                                                    renderInput={params => (
                                                                        <TextField
                                                                            {...params}
                                                                            id="field_1"
                                                                            label="Email"
                                                                            variant="outlined"
                                                                            autoComplete="field_1"
                                                                            value={values.email}
                                                                            error={errors.email && touched.email}
                                                                            onChange={onChangeEmail}
                                                                            name="field_1"
                                                                            helperText={(errors.email && touched.email) && errors.email}
                                                                        />
                                                                    )}
                                                                />
                                                                {renderContactTypeField(errors, values, handleChange, handleBlur, touched)}
                                                                <TextField
                                                                    disabled={existingUserForm}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.first_name && touched.first_name}
                                                                    id="first_name"
                                                                    label="First Name"
                                                                    name="first_name"
                                                                    value={values.first_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.first_name && touched.first_name) && errors.first_name}
                                                                    className={classes.textFieldLeft}
                                                                />
                                                                <TextField
                                                                    disabled={existingUserForm}
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.last_name && touched.last_name}
                                                                    id="last_name"
                                                                    label="Last Name"
                                                                    name="last_name"
                                                                    value={values.last_name}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.last_name && touched.last_name) && errors.last_name}
                                                                    className={classes.textField}
                                                                />
                                                            </Grid>
                                                            {!existingUserForm && <Grid container>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.password && touched.password}
                                                                    id="password"
                                                                    label="Password"
                                                                    name="password"
                                                                    type="password"
                                                                    value={values.password}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.password && touched.password) && errors.password}
                                                                    className={classes.textFieldLeft}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.repeat_password && touched.repeat_password}
                                                                    id="repeat_password"
                                                                    label="Repeat Password"
                                                                    name="repeat_password"
                                                                    type="password"
                                                                    value={values.repeat_password}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.repeat_password && touched.repeat_password) && errors.repeat_password}
                                                                    className={classes.textField}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.primary_phone && touched.primary_phone}
                                                                    id="primary_phone"
                                                                    label="Primary Phone"
                                                                    name="primary_phone"
                                                                    value={values.primary_phone}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.primary_phone && touched.primary_phone) && errors.primary_phone}
                                                                    className={classes.textFieldLeft}
                                                                />
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    error={errors.secondary_phone && touched.secondary_phone}
                                                                    id="secondary_phone"
                                                                    label="Secondary Phone"
                                                                    name="secondary_phone"
                                                                    value={values.secondary_phone}
                                                                    onChange={handleChange}
                                                                    onBlur={handleBlur}
                                                                    helperText={(errors.secondary_phone && touched.secondary_phone) && errors.secondary_phone}
                                                                    className={classes.textField}
                                                                />
                                                            </Grid>}
                                                            {!existingUserForm && renderUserSettingsFields(errors, values, handleChange, handleBlur, touched)}
                                                            <Grid container direction={'row-reverse'}>
                                                                <Grid item>
                                                                    {!!usersList.length && <Button
                                                                        onClick={() => {
                                                                            closeForm();
                                                                        }}
                                                                        className={classes.backButton}
                                                                    >
                                                                        Cancel
                                                                    </Button>}
                                                                    <Button
                                                                        variant="contained"
                                                                        type="submit"
                                                                        color="primary"
                                                                        className={classes.submit}
                                                                    >
                                                                        Save
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>

                                                    </Grid>
                                                </form>
                                            );
                                        }}
                                    </Formik>
                                </Paper>
                            </Grid>
                        </Grid>}
                        <Grid container direction={'row-reverse'}>
                            <Grid item>
                                <Fab
                                    color="default"
                                    size="small"
                                    component={Link}
                                    to={`/accounts`}
                                    variant={'extended'}
                                    className={classes.closeButton}
                                >
                                    Cancel
                                </Fab>
                                <Button
                                    onClick={() => {
                                        submitForm();
                                    }}
                                    variant="contained"
                                    color="primary"
                                    disabled={!userListHaveAPrimeryUser()}
                                    className={classes.submit}
                                >
                                    Save
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    };

    const renderAccountDetails = () => {
        return (<Grid container justify={'center'}>
            <Grid item sm={12} style={{marginBottom: '16px'}}>
                <Typography variant="h6" component="h6" className={classes.columnTitle}>
                    Account
                </Typography>
                {!account.isFetching && <Grid container className={classes.accountDetailsContainer}>
                    <Grid item sm={3}>
                        Name: <strong>{account.account.account_name}</strong>
                    </Grid>
                    <Grid item sm={3}>
                        Number: <strong>{account.account.account_number}</strong>
                    </Grid>
                    {account.account.service_address && <Grid item sm={6}>
                        Address: <strong>{account.account.service_address.street + ', ' + account.account.service_address.city}</strong>
                    </Grid>}
                </Grid>}
            </Grid>
        </Grid>);
    };

    return (
        <Container maxWidth="xl">
            <Grid container className={classes.titleContainer}>
                <Grid item sm={8}>
                    <Typography variant="h4" component="h4">
                        Account Users
                    </Typography>
                </Grid>
            </Grid>
            {renderAccountDetails()}
            {loading && <LoadingSpinner/>}
            {!loading && renderUserForm()}
        </Container>

    );
};

const mapStateToProps = state => ({
    account: state.account,
    users: state.users
});

const mapDispatchToProps = {
    getAccount,
    updateAccountUsers,
    getUsers,
    cleanAccount
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountUserPage);

